import React, { useEffect, useRef } from "react";

import { eventWithTime } from "@rrweb/types";
import rrwebPlayer from "rrweb-player";

import * as Styled from "./SessionReplayContainer.styles";
import { Spinner } from "../../../../components/Spinner/Spinner";

interface SessionReplayContainerProps {
  sessionReplayUrl: string;
}

export const SessionReplayContainer = ({ sessionReplayUrl }: SessionReplayContainerProps) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [events, setEvents] = React.useState<eventWithTime[]>([]);
  const replayContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    fetch(sessionReplayUrl, { method: "GET", mode: "cors", signal: abortController.signal })
      .then((response) => response.text())
      .then((jsonEvents) => {
        setEvents(JSON.parse(jsonEvents));
        setLoading(false);
      })
      .catch((error) => console.error("Error loading session replay", error));
    return () => {
      abortController.abort();
    };
  }, [sessionReplayUrl]);

  useEffect(() => {
    if (!loading && events.length > 0 && replayContainerRef.current) {
      const replayer = new rrwebPlayer({
        target: replayContainerRef.current,
        props: {
          events: events,
          width: 640,
          height: 400,
          speedOption: [1, 2, 4, 8],
        },
      });
      return () => {
        replayer.getReplayer().destroy();
      };
    }
  }, [loading, events]);

  return (
    <Styled.SessionReplayContainer>
      {loading ? <Spinner /> : <div ref={replayContainerRef}>{/* This is where the replay will be rendered */}</div>}
    </Styled.SessionReplayContainer>
  );
};
