import { rgba } from "polished";
import styled, { css } from "styled-components";

import Tooltip from "components/Tooltip/Tooltip";

import { vars } from "styles";

export const Dropdown = styled.div<{ $disabled?: boolean }>`
  position: relative;
  display: inline-flex;
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.6;
      cursor: default;
      span {
        opacity: 0.6;
        &:hover {
          opacity: 0.6;
        }
      }
    `}
`;

export const DropdownButton = styled.div<{ $originalStroke?: boolean }>`
  color: ${vars.colors.grey50};
  display: flex;
  align-items: center;
  user-select: none;

  svg {
    flex-shrink: 0;
    display: inline-block;
  }

  &:hover {
    color: inherit;
  }
  span {
    margin-right: 1rem;
  }

  .wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 3.6rem;
    border-radius: 1rem;
    background-color: ${vars.colors.grey100};

    > .icon {
      margin-right: 0;
    }
  }
`;

export const DropdownMenu = styled.div<{
  $active: boolean;
  scroll?: VoidFunction;
  $position?: "left" | "right";
  $positionVertical?: "top" | "bottom";
  $wide?: boolean;
  $button?: boolean;
  $maxHeight?: number;
  $horizontalOffset?: number;
}>`
  display: inline-block;
  min-width: 16rem;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 1rem 0 #1c1c1e;
  background-color: ${vars.colors.grey100};
  border: 1px solid ${vars.colors.grey90};
  padding: 1rem 0;
  margin: 0;
  position: absolute;
  right: 0;
  pointer-events: none;
  cursor: pointer;
  user-select: none;
  opacity: 0;
  z-index: -1;

  ${(props) =>
    props.$maxHeight &&
    css`
      max-height: ${props.$maxHeight}px;
    `}

  ${(props) =>
    props.$positionVertical === "top" &&
    css`
      top: -1rem;
      transform: translateY(-100%);
    `}

  ${(props) =>
    props.$positionVertical === "bottom" &&
    css`
      bottom: -1rem;
      transform: translateY(100%);
    `}
    /* active */
    ${(props) =>
    props.$active &&
    css`
      pointer-events: auto;
      opacity: 1;
      z-index: ${vars.zIndex.modalContent};
    `}
    /* wide */
    ${(props) =>
    props.$wide &&
    css`
      min-width: 24rem;
    `}
    /* position - left */
    ${(props) =>
    props.$position === "left" &&
    css`
      left: 0;
      right: auto;
    `}
    /* position - right */
    ${(props) =>
    props.$position === "right" &&
    css`
      right: 0;
    `}
    /* button */
    ${(props) =>
    props.$button &&
    css`
      top: 4rem;
    `};

  ${(props) =>
    props.$horizontalOffset &&
    css`
      left: ${props.$horizontalOffset}px;
    `}
`;

export const DropdownItem = styled.div<{
  $active?: boolean;
  $withSubmenu?: boolean;
  $wide?: boolean;
  $disabled?: boolean;
  $error?: boolean;
}>`
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.4rem 2rem;
  height: 4.2rem;
  white-space: nowrap;
  font-size: 1.4rem;
  color: ${vars.colors.grey50};
  line-height: 1.29;
  transition: ${vars.transitions.hover};
  cursor: pointer;

  > *:not(:last-child) {
    margin-right: 0.8rem;
  }

  &:hover {
    background-color: ${vars.colors.grey90};
    color: ${vars.colors.white};
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;

    > *:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  /* Active */

  ${(props) =>
    props.$active &&
    css`
      background-color: ${vars.colors.grey90};
      pointer-events: auto;
    `}

  ${(props) =>
    props.$error &&
    props.$active &&
    css`
      color: ${vars.colors.red}
      background-color: ${vars.colors.redLighter};
      pointer-events: auto;
    `}

  ${(props) =>
    props.$error &&
    css`
      color: ${vars.colors.red};
      &:hover {
        color: ${vars.colors.red};
        background-color: ${vars.colors.redLighter};
        pointer-events: auto;
      }
    `}
  
  ${(props) =>
    props.$disabled &&
    css`
      background-color: transparent;
      cursor: not-allowed;
      &:hover {
        background-color: transparent;
        color: ${vars.colors.grey50};
      }
    `}
  /* with Sub Menu */
  ${(props) =>
    props.$withSubmenu &&
    css`
      .icon-submenu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        color: ${vars.colors.grey70};
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
      }

      &:hover {
        .dropdown-submenu {
          pointer-events: auto;
          opacity: 1;
          z-index: 3;
        }
      }
    `}

  ${(props) =>
    props.$wide &&
    css`
      span {
        width: 24rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `} 
  .avatar {
    margin-right: 0.8rem;
  }

  .icon {
    margin-right: 0.8rem;
  }

  .dropdown-image {
    margin-right: 0.8rem;
    max-width: 2.8rem;
    height: 2.8rem;
  }
`;

export const DropdownSubMenu = styled.div<{
  $wide?: boolean;
  $position?: "left" | "right";
}>`
  display: inline-block;
  min-width: 16rem;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 1.3rem 0 #1c1c1e;
  background-color: ${vars.colors.grey100};
  padding: 1rem 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: calc(100% - 2px);
  transition: ${vars.transitions.hover};
  pointer-events: none;
  opacity: 0;
  z-index: -1;
  /* wide */
  ${(props) =>
    props.$wide &&
    css`
      min-width: 24rem;
    `} /* position - left */ ${(props) =>
    props.$position === "left" &&
    css`
      left: calc(100% - 2px);
      right: auto;
    `} /* position - right */ ${(props) =>
    props.$position === "right" &&
    css`
      right: calc(100% - 2px);
      left: auto;
    `}
`;

export const DropdownDivider = styled.div`
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: ${vars.colors.grey90};
  cursor: default;
`;

export const DropdownHeading = styled.div`
  color: ${vars.colors.grey60};
  font-size: 1.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
`;

export const SwitchAppDropdownHeading = styled(DropdownHeading)`
  display: flex;
  justify-content: flex-start;
  cursor: default;
`;

export const DropdownScroll = styled.div<{ cx?: string }>`
  overflow-y: overlay;
  ${(props) => props.cx};

  &::-webkit-scrollbar {
    width: 0.8rem;
  }
`;

export const Image = styled.img`
  width: 2rem;
  margin-right: 1.2rem;
  flex-shrink: 0;
  display: inline-block;
`;

export const Overlay = styled.div<{ active?: boolean }>`
  background-color: ${rgba("#000", 0)};
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: ${vars.transitions.hover};
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  /* active */
  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: auto;
      z-index: ${vars.zIndex.modalOverlay};
      cursor: default;
    `}
`;

export const NumContainer = styled.div`
  border-radius: 1.4rem;
  background: ${vars.colors.grey90};
  color: ${vars.colors.grey60};
  font-size: 1.1rem;
  margin-left: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-top: 2px;
  min-width: 2.2rem;
  display: flex;
  justify-content: center;
`;

export const NumTooltip = styled(Tooltip)`
  span {
    margin-right: 1rem;
  }
`;
