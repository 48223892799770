import styled from "styled-components";

import BaseHeading from "components/Heading/Heading";
import BaseParagraph from "components/Paragraph/Paragraph";

import { vars } from "styles";
import { Flex as BaseFlex } from "styles/reusable/Flex/Flex.styles";

export const AccordionItem = styled.div`
  width: 100%;
  background-color: transparent;
`;

export const Header = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const Content = styled.div`
  color: ${vars.colors.grey30};
  margin-top: 2rem;
`;

export const IconContainer = styled.div`
  svg {
    width: 2.4rem;
    height: 2.4rem;

    path {
      stroke: ${vars.colors.grey70};
    }
  }
`;

export const Heading = styled(BaseHeading)`
  font-weight: 500;
`;

export const Flex = styled(BaseFlex)`
  flex-direction: row;
  cursor: pointer;
`;

export const Paragraph = styled(BaseParagraph)`
  color: ${vars.colors.grey50};
  word-break: break-word;
  margin-top: 0.4rem;
  margin-right: 2rem;
`;
